import React, { Fragment } from "react";

export const PORTUGUESE = {
  LANG_CODE: "pt",
  COUNTRY_CODE: "pt",
  CURRENCY: {
    symbol: "€",
    name: "EUR",
    id: "0",
    minPaymentAmount: "0,01",
    maxPaymentAmount: "15000",
    billingLabel: "EUR",
    traderKey: "Vazio",
  },
  FOOTER: {
    copyright: "Eligma Ltd.",
    version: "versão",
  },
  LOGIN: {
    username: "Nome de utilizador",
    password: "Palavra-passe",
    usernameEmptyError: "Introduzir nome de utilizador",
    passwordEmptyError: "Introduzir palavra-passe",
    login: "INICIAR SESSÃO",
    notAuthorized: "Não autorizado",
    hidePassword: "Ocultar",
    showPassword: "Mostrar",
    invalidCredentials: "Nome de utilizador ou palavra-passe incorretos.",
  },
  HEADER: {
    help: "AJUDA",
    callAgent: "support@gocrypto.com",
    agentNumber: "",
  },
  SIDEBAR: {
    logout: "Sair",
    newPayment: "Criar pagamento",
    archive: "Arquivar",
    billing: "Faturamento",
    faq: "Perguntas frequentes",
  },
  ARCHIVE: {
    overviewTitle: "Lista de transações recebidas",
    refreshLabel: "ATUALIZAR",
    exportLabel: "EXPORTAR",
    tableHeaders: {
      ID: "ID",
      lastUpdatedTime: "HORA",
      lastUpdatedDate: "DATA",
      lastUpdated: (
        <span>
          HORA <span className="mobile-hidden"> DATA</span>
        </span>
      ),
      amount: "VALOR",
      combinedAmount: (
        <span>
          VALOR <span className="mobile-hidden"> </span>
        </span>
      ),
      cryptoCurrency: "MOEDA",
      cryptoAmount: "VALOR EM CRIPTOMOEDA",
      print: "IMPRIMIR",
      status: "ESTADO",
      referenceNumber: "REFERÊNCIA",
      revert: "Reverta",
    },
    export: {
      startDate: "Data inicial",
      endDate: "Data final",
      error: "Selecione um intervalo de datas.",
      exportTitle: "Exportar pagamentos",
      lastMonth: "Mês passado",
      showResults: "MOSTRAR RESULTADOS",
      successfulPayments: "Pagamentos efetuados com sucesso",
    },
    printLabel: "Imprimir",
    print: {
      amountLabel: "Valor da moeda",
      transactionInfoLabel: "Informações sobre a transação",
      dateTimeLabel: "Data e hora",
    },
    emptyList: "Não há pagamentos no arquivo.",
  },
  PAYMENT: {
    stepLabel: "passo",
    fromLabel: "de",
    createPayment: "Criar pagamento",
    paymentReady: "Pronto para pagamento",
    paymentFinished: "Concluir pagamento",
    amount: "Valor de pagamento",
    amountErrorLow: "O valor introduzido é demasiado baixo.",
    amountErrorHigh: "O valor introduzido é demasiado elevado.",
    referenceNumberLabel: "Número de referência",
    createPaymentButton: "Continuar para efetuar pagamento",
    cancelPaymentButton: "cancelar pagamento",
    qrCodeReady: "Código QR pronto para leitura",
    resetPaymentButton: "Criar pagamento",
    paymentStatus: {
      CRYPTO_PAYMENT_STATUS_OPENED: "ABRIR",
      CRYPTO_PAYMENT_STATUS_IN_PAYMENT: "EM PAGAMENTO",
      CRYPTO_PAYMENT_STATUS_PAID: "BEM-SUCEDIDO",
      CRYPTO_PAYMENT_STATUS_PROCESSING: "A PROCESSAR",
      CRYPTO_PAYMENT_STATUS_AUTO_CLOSED: "EXPIRADO",
      CRYPTO_PAYMENT_STATUS_FAILED: "Falhou",
      CRYPTO_PAYMENT_STATUS_NOT_VALID: "INVÁLIDO",
      CRYPTO_PAYMENT_STATUS_REVERTED: "REEMBOLSADO",
      CRYPTO_PAYMENT_STATUS_CANCELED: "CANCELADO",
      CRYPTO_PAYMENT_STATUS_UNDERPAID: "MUITO BAIXO",
    },
    paymentStatusLabel: {
      CRYPTO_PAYMENT_STATUS_OPENED: "Código QR pronto para leitura",
      CRYPTO_PAYMENT_STATUS_PAID: "Pagamento efetuado com sucesso",
      CRYPTO_PAYMENT_STATUS_AUTO_CLOSED: "O prazo para o pagamento expirou.",
      CRYPTO_PAYMENT_STATUS_UNDERPAID:
        "Pagamento demasiado baixo.\nPara obter assistência, contacte a GoCrypto em: support@gocrypto.com",
    },
    currencyNotice: "Vazio",
  },
  EXPORT: {
    exportTransactionsLabel: "EXPORTAR TRANSAÇÕES",
    timeRangeLabel: "Período de tempo",
    fileTypeLabel: "Tipo de ficheiro",
    exportLabel: "EXPORTAR",
    lastMonth: "Vazio",
    showResults: "Vazio",
  },
  REPORT: {
    dailyReport: {
      title: "Faturamento",
      exportLabel: "EXPORTAR",
      print: "IMPRIMIR",
      noTransactions: "Nenhuma transação",
      currencyLabel: "Valor em",
      totalLabel: "Total em",
      back: "VOLTAR",
      amountLabel: "MONTANTE",
      currencyInLabel: "em",
    },
  },
  ERROR: {
    unknownCause: "Este foi um erro inesperado.",
    reload: "Primeiro tente recarregar/limpar a memória transitória.",
    report: "Se o problema persistir, comunique-o.",
  },
  GOCRYPTO: "Vazio",
  TOOLTIPS: {
    PAYMENT: {
      mobile: {
        0: "Utilize o teclado para introduzir o valor de pagamento.",
        1: "Pressione Continuar para efetuar o pagamento. Abrirá assim a lacuna para digitalizar o código QR.",
      },
      tablet: {
        0: "Utilize o teclado para introduzir o valor de pagamento.",
        1: "Pressione Continuar para efetuar o pagamento. Abrirá assim a lacuna para digitalizar o código QR.",
      },
      desktop: {
        0: "Utilize o teclado para introduzir o valor de pagamento.",
        1: "Pressione Continuar para efetuar o pagamento. Abrirá assim a lacuna para digitalizar o código QR.",
      },
    },
    ARCHIVE: {
      mobile: {
        0: "Ver pagamentos de acordo com a hora e o estado.",
        1: "Exportar pagamentos de acordo com a hora e o sucesso em formato PDF ou CSV.",
      },
      tablet: {
        0: "Ver pagamentos de acordo com a hora e o estado.",
        1: "Exportar pagamentos de acordo com a hora e o sucesso em formato PDF ou CSV.",
      },
      desktop: {
        0: "Ver pagamentos de acordo com a hora e o estado.",
        1: "Exportar pagamentos de acordo com a hora e o sucesso em formato PDF ou CSV.",
      },
    },
    next: "SEGUINTE",
  },
  FAQ: {
    title: "webPOS - perguntas frequentes",
    QA: [
      {
        question: "Como posso criar o pagamento GoCrypto?",
        answer: (
          <Fragment>
            <p>
              Selecione "Novo pagamento" no menu principal e coloque o valor do
              pagamento no campo de entrada determinado, usando o teclado ou o
              ecrã tátil. Pode ser adicionado, de forma opcional, um número de
              referência.
            </p>
            <p>
              Clique em "Criar pagamento" e será gerado um código QR. Peça ao
              comprador para digitalizar este código QR ou o adesivo, colocado
              em frente do caixa, apenas se o ecrã não estiver facilmente
              acessível.
            </p>
            <p>
              Quando o comprador concluir o processo, a seta verde aparecerá com
              o sinal "Pagamento efetuado com sucesso". Isso indica que os
              fundos são recebidos e que pode emitir uma fatura.{" "}
            </p>
            <p>
              Para as suas necessidades de conta, pode entrar nos "Arquivos" e
              imprimir o comprovativo.{" "}
            </p>
          </Fragment>
        ),
      },
      {
        question: "O pagamento expirou. O que é que isso significa?",
        answer: (
          <Fragment>
            <p>
              Quando o pagamento foi criado, o código QR apareceu em conjunto
              com um temporizador de contagem regressiva. A partir deste
              momento, o utilizador terá de concluir o pagamento em 5 minutos.
              Se demorar mais tempo, o processo expira e terá de criar o
              pagamento novamente.{" "}
            </p>
          </Fragment>
        ),
      },
      {
        question:
          "Coloquei o montante de pagamento errado e o código QR já está gerado. O que faço agora?",
        answer: (
          <Fragment>
            <p>
              Não se preocupe, basta clicar no botão "Cancelar pagamento" e
              repetir o processo novamente.{" "}
            </p>
          </Fragment>
        ),
      },
      {
        question:
          "Onde posso rever o histórico de pagamentos e fazer uma exportação?",
        answer: (
          <Fragment>
            <p>
              {" "}
              Clique em Arquivos no menu principal para visualizar o histórico
              completo de pagamentos. Pode filtrar os resultados exibidos por
              estado e data de pagamento. Para as suas necessidades de conta, os
              Arquivos podem ser exportados em formato CSV ou PDF.
            </p>
          </Fragment>
        ),
      },
      {
        question: "Posso gerar o extrato de contas diário?",
        answer: (
          <Fragment>
            <p>
              {" "}
              Claro, clique em Faturamento no menu principal, onde pode imprimir
              o extrato diário e onde os pagamentos efetuados com moedas
              individuais são consubstanciados.{" "}
            </p>
          </Fragment>
        ),
      },
      {
        question: "Que criptomoedas são suportadas?",
        answer: (
          <Fragment>
            <p>
              Crypto enables payments with 40 cryptocurrencies. Bitcoin, Bitcoin
              Cash, Ether, the GoC token, Litecoin and the Viberate token
              are just some of them. However, you do not need any knowledge
              about them. Once the user scans the QR code, they select the
              payment currency in their crypto wallet application, and the
              process for you is the same in all cases.{" "}
            </p>
            <p>
              In addition to cryptocurrencies, GoCrypto also supports payments
              with pre-deposited Euro value in some countries.{" "}
            </p>
          </Fragment>
        ),
      },
      {
        question:
          "Que carteiras podem usar os compradores para concluir o pagamento?",
        answer: (
          <Fragment>
            <p>
              Buyers can use the Elly Wallet, Binance Pay (these two support
              multiple currencies) and the Bitcoin.com Wallet (supports Bitcoin
              Cash). The process is completely the same for you in all cases.
            </p>
          </Fragment>
        ),
      },
      {
        question: "O pagamento com moeda comum é suportado?",
        answer: (
          <Fragment>
            <p>
              Sim, além de criptomoedas em algumas regiões, o GoCrypto também
              aceita pagamentos com valor em euro pré-depositado.
            </p>
          </Fragment>
        ),
      },
      {
        question: "O meu comprador está a pedir um reembolso.",
        answer: (
          <Fragment>
            <p>
              Depois que o pagamento for concluído com sucesso, os fundos não
              poderão ser reembolsados pelo sistema GoCrypto e o reembolso
              deverá ser feito pelo comerciante.
            </p>
          </Fragment>
        ),
      },
      {
        question:
          "Estou com problemas ao criar um pagamento ou ao usar o GoCrypto POS.",
        answer: (
          <Fragment>
            <p>
              Você pode enviar um email para{" "}
              <a href="mailto:support@gocrypto.com">support@gocrypto.com</a>.{" "}
            </p>
          </Fragment>
        ),
      },
    ],
  },
};
