import React, { Fragment } from "react";

export const HUNGARIAN = {
  LANG_CODE: "hu",
  COUNTRY_CODE: "hu",
  CURRENCY: {
    symbol: "€",
    name: "EUR (euró)",
    id: "0",
    minPaymentAmount: "0,01",
    maxPaymentAmount: "15000",
    traderKey: "",
    billingLabel: "EUR (euró)",
  },
  FOOTER: {
    copyright: "Eligma Ltd.",
    version: "verzió",
  },
  LOGIN: {
    username: "Felhasználónév",
    password: "Jelszó",
    usernameEmptyError: "Adja meg felhasználónevet",
    passwordEmptyError: "Adja meg a jelszót",
    login: "BELÉPÉS",
    notAuthorized: "Nem engedélyezett",
    hidePassword: "Elrejtés",
    showPassword: "Mutatás",
    invalidCredentials: "Helytelen felhasználónév vagy jelszó",
  },
  HEADER: {
    help: "SEGÍTSÉG",
    callAgent: "support@gocrypto.com",
    agentNumber: "",
  },
  SIDEBAR: {
    logout: "Kijelentkezés",
    newPayment: "Új kifizetés",
    archive: "Archívum",
    billing: "Számlázás",
    faq: "GYIK",
  },
  ARCHIVE: {
    overviewTitle: "Beérkető tranzakciók listája",
    refreshLabel: "FRISSÍTÉS",
    exportLabel: "EXPORT",
    tableHeaders: {
      ID: "ID",
      lastUpdatedTime: "IDŐ",
      lastUpdatedDate: "DÁTUM",
      lastUpdated: (
        <span>
          {" "}
          IDŐ <span className="mobile-hidden"> DÁTUM </span>{" "}
        </span>
      ),
      amount: "ÉRTÉK",
      combinedAmount: (
        <span>
          {" "}
          ÉRTÉK <span className="mobile-hidden"> </span>{" "}
        </span>
      ),
      cryptoCurrency: "PÉNZNEM",
      cryptoAmount: "ÉRTÉK KRIPTOVALUTÁBAN",
      print: "NYOMTATÁS",
      status: "ÁLLAPOT",
      referenceNumber: "AZONOSÍTÓ",
      revert: "Visszavonás",
    },
    export: {
      startDate: "Ettől",
      endDate: "Eddig",
      error: "Dátumtartomány kiválasztása",
      exportTitle: "Kifizetések exportálása",
      lastMonth: "Múlt hónap",
      showResults: "AZ EREDMÉNYEK MUTATÁSA",
      successfulPayments: "Sikeres fizetések",
    },
    printLabel: "Nyomtatás",
    print: {
      amountLabel: "Pénznem összege",
      transactionInfoLabel: "Tranzakcióra vonatkozó információk",
      dateTimeLabel: "Dátum és idő",
    },
    emptyList: "Nincs kifizetés az archívumban",
  },
  PAYMENT: {
    stepLabel: "lépés",
    fromLabel: "a",
    createPayment: "Kifizetés létrehozása",
    paymentReady: "Fizetésre kész",
    paymentFinished: "Kifizetett összeg",
    amount: "Végösszeg",
    amountErrorLow: "A megadott összeg túl alacsony",
    amountErrorHigh: "A megadott összeg túl magas",
    referenceNumberLabel: "Referenciaszám",
    createPaymentButton: "Tovább a kifizetéshez",
    cancelPaymentButton: "Fizetés visszavonása",
    qrCodeReady: "A QR-kód készen áll a szkennelésre",
    resetPaymentButton: "Új kifizetés",
    paymentStatus: {
      CRYPTO_PAYMENT_STATUS_OPENED: "NYITOTT",
      CRYPTO_PAYMENT_STATUS_IN_PAYMENT: "KIFIZETÉS ALATT",
      CRYPTO_PAYMENT_STATUS_PAID: "SIKERES",
      CRYPTO_PAYMENT_STATUS_PROCESSING: "FELDOLGOZÁS ALATT",
      CRYPTO_PAYMENT_STATUS_AUTO_CLOSED: "LEJÁRT",
      CRYPTO_PAYMENT_STATUS_FAILED: "SIKERTELEN",
      CRYPTO_PAYMENT_STATUS_NOT_VALID: "ÉRVÉNYTELEN",
      CRYPTO_PAYMENT_STATUS_REVERTED: "VISSZATÉRÍTVE",
      CRYPTO_PAYMENT_STATUS_CANCELED: "TÖRÖLVE",
      CRYPTO_PAYMENT_STATUS_UNDERPAID: "ELÉGTELEN",
    },
    paymentStatusLabel: {
      CRYPTO_PAYMENT_STATUS_OPENED: "A QR-kód készen áll a szkennelésre",
      CRYPTO_PAYMENT_STATUS_PAID: "Sikeres fizetés",
      CRYPTO_PAYMENT_STATUS_AUTO_CLOSED:
        "A fizetéshez rendelkezésre álló idő lejárt",
      CRYPTO_PAYMENT_STATUS_UNDERPAID:
        "Túl alacsony összeg.\nSegítségért kérjük vegye fel a kapcsolatot a GoCrypto-val a következő e-mail címen: support@gocrypto.com",
    },
    currencyNotice: "",
  },
  EXPORT: {
    exportTransactionsLabel: "TRANZAKCIÓK EXPORTÁLÁSA",
    timeRangeLabel: "Időszak",
    fileTypeLabel: "Fájltípus",
    exportLabel: "EXPORT",
    lastMonth: "",
    showResults: "",
  },
  REPORT: {
    dailyReport: {
      title: "Számlázás",
      exportLabel: "EXPORT",
      print: "NYOMTATÁS",
      noTransactions: "Nincs tranzakció",
      currencyLabel: "Beérkező érték",
      totalLabel: "Összesen",
      back: "VISSZA",
      amountLabel: "ÖSSZEG",
      currencyInLabel: "ebben a pénznemben:",
    },
  },
  ERROR: {
    unknownCause: "Váratlan hiba történt",
    reload: "Kérjük, először próbálja meg újratölteni/törölni a cache-t",
    report: "Ha a probléma továbbra is fennáll, kérjük, jelentse",
  },
  GOCRYPTO: "",
  TOOLTIPS: {
    PAYMENT: {
      mobile: {
        0: "A billentyűzet segítségével írja be a fizetendő összeget.",
        1: "Nyomja meg a Folytatás gombot a fizetéshez. Ezzel megnyílik a QR-kód beolvasásához szükséges képernyő.",
      },
      tablet: {
        0: "A billentyűzet segítségével írja be a fizetendő összeget.",
        1: "Nyomja meg a Folytatás gombot a fizetéshez. Ezzel megnyílik a QR-kód beolvasásához szükséges képernyő.",
      },
      desktop: {
        0: "A billentyűzet segítségével írja be a fizetendő összeget.",
        1: "Nyomja meg a Folytatás gombot a fizetéshez. Ezzel megnyílik a QR-kód beolvasásához szükséges képernyő.",
      },
    },
    ARCHIVE: {
      mobile: {
        0: "Tekintse meg a kifizetéseket dátum és állapot szerint.",
        1: "Exportálja a kifizetéseket dátum és teljesítés függvényében PDF vagy CSV formátumban.",
      },
      tablet: {
        0: "Tekintse meg a kifizetéseket dátum és állapot szerint.",
        1: "Exportálja a kifizetéseket dátum és teljesítés függvényében PDF vagy CSV formátumban.",
      },
      desktop: {
        0: "Tekintse meg a kifizetéseket dátum és állapot szerint.",
        1: "Exportálja a kifizetéseket dátum és teljesítés függvényében PDF vagy CSV formátumban.",
      },
    },
    next: "KÖVETKEZŐ",
  },
  FAQ: {
    title: "webPOS - gyakran ismételt kérdések",
    QA: [
      {
        question: "Hogyan hozhatok létre GoCrypto kifizetést?",
        answer: (
          <Fragment>
            {" "}
            <p>
              {" "}
              Válassza a „Új kifizetés" menüpontot a főmenüben és írja be a
              fizetés összegét a dedikált beviteli mezőbe a billentyűzet vagy az
              érintőképernyő segítségével. Opcionálisan hozzáadható egy
              referenciaszám is.{" "}
            </p>{" "}
            <p>
              {" "}
              Kattintson a „Kifizetés létrehozása” elemre. Létrejön egy QR-kód.
              Kérje meg a vásárlót, hogy olvassa be ezt a QR-kódot vagy a
              matricát, amelyet a pénztár előtt talál, ha a képernyő nem érhető
              el könnyen.{" "}
            </p>
            <p>
              {" "}
              Ha a vevő befejezi a folyamatot, akkor megjelenik a zöld nyíl,
              amelynek jelentése: „Fizetés sikeres”. Ez azt jelzi, hogy a
              pénzeszközök megérkeztek, és ki lehet állítani a számlát.{" "}
            </p>{" "}
            <p>
              {" "}
              A számlák megtekintéséhez lépjen be az „Archívumba”, ahonnan ki is
              nyomtathatja a visszaigazoló slipeket.{" "}
            </p>{" "}
          </Fragment>
        ),
      },
      {
        question: "A fizetés lejárt. Mit jelent ez?",
        answer: (
          <Fragment>
            {" "}
            <p>
              {" "}
              A fizetés létrehozásakor megjelenik egy QR-kód és egy
              visszaszámláló időzítő. Ettől kezdve a felhasználónak 5 perc alatt
              teljesítenie kell a kifizetést. Ha ez hosszabb időt vesz igénybe,
              akkor a folyamat lejár és újra kell kezdeni.{" "}
            </p>{" "}
          </Fragment>
        ),
      },
      {
        question:
          "Rossz fizetési összeget adtam meg és a QR-kód már elő lett állítva. Ilyen esetben mi a teendő?",
        answer: (
          <Fragment>
            {" "}
            <p>
              {" "}
              Ne aggódjon, egyszerűen kattintson a „Fizetés megszakítása” gombra
              és ismételje meg a folyamatot.{" "}
            </p>{" "}
          </Fragment>
        ),
      },
      {
        question:
          "Hol lehet áttekinteni a korábbi kifizetéseket és exportálni az adatokat?",
        answer: (
          <Fragment>
            {" "}
            <p>
              {" "}
              Kattintson a főmenü Archívum elemére a kifizetési előzmények
              áttekintéséhez. A kapott eredményeket a fizetési állapot és a
              dátum alapján is szűrheti. Számviteli igényeinek megfelelően az
              Archívum CSV vagy PDF formátumban is exportálható.{" "}
            </p>{" "}
          </Fragment>
        ),
      },
      {
        question: "Lehetséges napi számlakivonat generálása?",
        answer: (
          <Fragment>
            {" "}
            <p>
              {" "}
              Természetesen! Kattintson a Fizetés opcióra a főmenüben, ahol
              kinyomtathatja a napi kimutatását, illetve egyesíthetőek az egyes
              valutákkal teljesített kifizetések.{" "}
            </p>{" "}
          </Fragment>
        ),
      },
      {
        question: "Mely kriptovaluta elfogadását támogatja a rendszer?",
        answer: (
          <Fragment>
            <p>
              Crypto enables payments with 40 cryptocurrencies. Bitcoin, Bitcoin
              Cash, Ether, the GoC token, Litecoin and the Viberate token
              are just some of them. However, you do not need any knowledge
              about them. Once the user scans the QR code, they select the
              payment currency in their crypto wallet application, and the
              process for you is the same in all cases.{" "}
            </p>
            <p>
              In addition to cryptocurrencies, GoCrypto also supports payments
              with pre-deposited Euro value in some countries.{" "}
            </p>
          </Fragment>
        ),
      },
      {
        question: "Mely pénztárcákat használhatják a vásárlók a fizetéshez?",
        answer: (
          <Fragment>
            <p>
              Buyers can use the Elly Wallet, Binance Pay (these two support
              multiple currencies) and the Bitcoin.com Wallet (supports Bitcoin
              Cash). The process is completely the same for you in all cases.
            </p>
          </Fragment>
        ),
      },
      {
        question: "Támogatott-e a normál valutával történő fizetés?",
        answer: (
          <Fragment>
            {" "}
            <p>
              {" "}
              Igen, néhány régióban a kriptovaluták mellett a GoCrypto támogatja
              az előre letétbe helyezett euró alapú fizetéseket is.{" "}
            </p>{" "}
          </Fragment>
        ),
      },
      {
        question: "A vevőm pénzvisszafizetést kér.",
        answer: (
          <Fragment>
            {" "}
            <p>
              {" "}
              A fizetés sikeres teljesítése után a pénzeszközök nem téríthetők
              vissza a GoCrypto rendszeren keresztül, a visszatérítést a
              kereskedőnek kell megtennie.{" "}
            </p>{" "}
          </Fragment>
        ),
      },
      {
        question:
          "Segítségre lenne szükségem egy fizetés létrehozásával vagy a GoCrypto POS használatával kapcsolatban.",
        answer: (
          <Fragment>
            <p>
              {" "}
              Küldhet e-mailt a következő{" "}
              <a href="mailto:support@gocrypto.com">
                support@gocrypto.com
              </a>{" "}
              címre.
            </p>
          </Fragment>
        ),
      },
    ],
  },
};
