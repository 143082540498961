import React, { Fragment } from "react";

export const TURKISH = {
  LANG_CODE: "tr",
  COUNTRY_CODE: "tr",
  CURRENCY: {
    symbol: "TRY",
    name: "TRY",
    id: "12",
    minPaymentAmount: "0.04",
    maxPaymentAmount: "97800.0",
    billingLabel: "TRY",
    traderKey: "TRY/EUR.traders.tl_trader",
  },
  FOOTER: {
    copyright: "Eligma Ltd. Şti.",
    version: "sürüm",
  },
  LOGIN: {
    username: "Kullanıcı adı",
    password: "Parola",
    usernameEmptyError: "Kullanıcı adınızı girin",
    passwordEmptyError: "Parolayı girin",
    login: "OTURUM AÇ",
    notAuthorized: "Yetkili değil",
    hidePassword: "Parolayı gizle",
    showPassword: "Parolayı göster",
    invalidCredentials: "Geçersiz kullanıcı adı veya parola",
  },
  HEADER: {
    help: "YARDIM",
    callAgent: "support@gocrypto.com",
    agentNumber: "",
  },
  SIDEBAR: {
    logout: "Çıkış yap",
    newPayment: "Yeni ödeme",
    archive: (
      <span>
        <span className="mobile-hidden">Ödeme arşivi</span>{" "}
        <span className="mobile-only">Arşivi</span>
      </span>
    ),
    billing: "Fatura",
    faq: "SSS",
  },
  ARCHIVE: {
    overviewTitle: "İşlemin geçmişi",
    refreshLabel: "Yenileme",
    exportLabel: "DIŞA AKTAR",
    tableHeaders: {
      ID: "ID",
      lastUpdatedTime: "Son güncelleme zamanı",
      lastUpdatedDate: "Son güncelleme tarihi",
      lastUpdated: "Süre",
      amount: "PARA MİKTARI",
      combinedAmount: "PARA DEĞERİ",
      cryptoCurrency: "KRİPTO PARA DEĞERİ",
      cryptoAmount: "KRİPTO PARA MİKTARI",
      print: "YAZDIR",
      status: "İŞLEM DURUMU",
      referenceNumber: "REFERANS NUMARASI",
      revert: "Geri Al",
    },
    export: {
      startDate: "Başlangıç",
      endDate: "Son",
      error: "Tarih aralığı seçin",
      exportTitle: "Ödemeleri dışa aktarın",
      lastMonth: "Geçen ay",
      showResults: "SONUÇLARI GÖSTER",
      successfulPayments: "Başarılı ödemeler",
    },
    printLabel: "Yazdır",
    print: {
      amountLabel: "Para miktarı",
      transactionInfoLabel: "İşlem bilgisi",
      dateTimeLabel: "Tarih ve saat",
    },
    emptyList: "Arşivde ödeme yok",
  },
  PAYMENT: {
    stepLabel: "Adım",
    fromLabel: "/",
    createPayment: "Ödeme miktarını girin",
    paymentReady: "Ödemeye hazır",
    paymentFinished: "Ödeme yapıldı",
    amount: "Ödeme miktarı",
    amountErrorLow: "Girilen miktar çok düşük",
    amountErrorHigh: "Girilen miktar çok yüksek",
    referenceNumberLabel: "Referans numarası",
    createPaymentButton: "Ödemeye devam et",
    cancelPaymentButton: "Ödemeyi iptal et",
    qrCodeReady: "QR kod taramaya hazır",
    resetPaymentButton: "Ödeme miktarını girin",
    paymentStatus: {
      CRYPTO_PAYMENT_STATUS_OPENED: "AÇIK",
      CRYPTO_PAYMENT_STATUS_IN_PAYMENT: "ÖDEMEDE",
      CRYPTO_PAYMENT_STATUS_PAID: "BAŞARILI",
      CRYPTO_PAYMENT_STATUS_PROCESSING: "İŞLENİYOR",
      CRYPTO_PAYMENT_STATUS_AUTO_CLOSED: "SÜRE BİTTİ",
      CRYPTO_PAYMENT_STATUS_FAILED: "BAŞARISIZ",
      CRYPTO_PAYMENT_STATUS_NOT_VALID: "GEÇERSİZ",
      CRYPTO_PAYMENT_STATUS_REVERTED: "İade",
      CRYPTO_PAYMENT_STATUS_CANCELED: "IPTAL EDILDI",
      CRYPTO_PAYMENT_STATUS_UNDERPAID: "ÇOK DÜŞÜK",
    },
    paymentStatusLabel: {
      CRYPTO_PAYMENT_STATUS_OPENED: "QR kod taramaya hazır",
      CRYPTO_PAYMENT_STATUS_PAID: "Ödeme başarılı",
      CRYPTO_PAYMENT_STATUS_AUTO_CLOSED: "Süre bitti",
      CRYPTO_PAYMENT_STATUS_UNDERPAID:
        "Ödeme çok düşük.\nYardım için lütfen GoCrypto ile şu adresten iletişime geçin: support@gocrypto.com",
    },
    currencyNotice:
      "Avro değeri Eligma nın sağladığı döviz kuru baz alınmıştır. Döviz kurlarının alındığı tarih:",
  },
  EXPORT: {
    exportTransactionsLabel: "İşlemleri dışa aktarın",
    timeRangeLabel: "Zaman çerçevesi",
    fileTypeLabel: "Dosya türü",
    exportLabel: "DIŞA AKTAR",
    lastMonth: "Geçen ay",
    showResults: "Sonuçları göster",
  },
  REPORT: {
    dailyReport: {
      title: "Ödemelerin hesaplanması",
      exportLabel: "DIŞA AKTAR",
      print: "YAZDIR",
      noTransactions: "İşlem yok",
      currencyLabel: "Para değeri",
      totalLabel: "Toplam tutar",
      back: "GERİ",
      amountLabel: "Miktari",
      currencyInLabel: "",
    },
  },
  ERROR: {
    unknownCause: "Beklenmeyen bir hataydı",
    reload: "Lütfen sayfayı yenilemeyi deneyin",
    report: "Sorun devam ederse, lütfen bize bildirin",
  },
  GOCRYPTO: "1",
  TOOLTIPS: {
    PAYMENT: {
      mobile: {
        0: "Ödeme miktarını girmek için klavyeyi kullanın",
        1: "»Ödemeye devam et« düğmesine basın. Bu işlem QR kodunu taramak için ekranı açar.",
      },
      tablet: {
        0: "Ödeme miktarını girmek için klavyeyi kullanın",
        1: "»Ödemeye devam et« düğmesine basın. Bu işlem QR kodunu taramak için ekranı açar.",
      },
      desktop: {
        0: "Ödeme miktarını girmek için klavyeyi kullanın",
        1: "»Ödemeye devam et« düğmesine basın. Bu işlem QR kodunu taramak için ekranı açar.",
      },
    },
    ARCHIVE: {
      mobile: {
        0: "Ödeme süresini ve durumunu ayarlayın (başarılı / başarısız) ve ödemeleri inceleyin …",
        1: "… ya da ödemeleri PDF ve CSV  formatında dışa aktarın",
      },
      tablet: {
        0: "Ödeme süresini ve durumunu ayarlayın (başarılı / başarısız) ve ödemeleri inceleyin …",
        1: "… ya da ödemeleri PDF ve CSV  formatında dışa aktarın",
      },
      desktop: {
        0: "Ödeme süresini ve durumunu ayarlayın (başarılı / başarısız) ve ödemeleri inceleyin …",
        1: "… ya da ödemeleri PDF ve CSV  formatında dışa aktarın",
      },
    },
    next: "BİR SONRAKİ",
  },
  FAQ: {
    title: "Sıkça sorulan sorular",
    QA: [
      {
        question: "GoCrypto ile ödeme nasıl yapılır?",
        answer: (
          <Fragment>
            <p>
              Ana menüden Yeni Ödeme seçeneğine tıklayarak özel giriş alanina
              ödeme miktarını girin. İsteğe bağlı olarak referans numara da
              eklenebilir.{" "}
            </p>
            <p>
              Ödeme isteği oluştur seçeneğine tıklayarak bir QR kod
              öluşturulacak. QR kodunu veya kasiyer masanızın önüne
              yerleştirilen etiketi müşteriden taramasını isteyebilirsiniz.
            </p>
            <p>
              İşlemi tamamladığında yeşil ok işaretiyle Ödeme başarılı durumu
              görüldüğünde para miktarı alındığını ve fatura düzenleyebileceğini
              gösterir.
            </p>
            <p>
              Muhasebe amacıyla Ödeme Arşivi seçeneğine tıklayarak onay fişini
              yazdırabilirsiniz.
            </p>
          </Fragment>
        ),
      },
      {
        question: "Süre bitti bildirim ne anlama geliyor?",
        answer: (
          <Fragment>
            <p>
              Ödeme oluşturulduğunda QR kod ile birlikte bir zamanlayıcı görünür
              ve kullanıcının 5 dakika içinde ödemeyi tamamlaması gerekir. Bu
              süreden sonra işlem sona erer ve ödemeyi bir kez daha yapmanız
              gerekir.{" "}
            </p>
          </Fragment>
        ),
      },
      {
        question:
          "Yanlış bir ödeme tutarı girdim ve QR kod oluşturuldu. Şimdi ne yapmalıyım?",
        answer: (
          <Fragment>
            <p>
              {" "}
              Endişeye gerek yok, sadece Ödemeyi iptal et düğmesini tıklayın ve
              işlemi tekrarlayın.
            </p>
          </Fragment>
        ),
      },
      {
        question:
          "Ödemelerin geçmişini nerede inceleyebilirim ve dışa aktarı nereden yapabilirim?",
        answer: (
          <Fragment>
            <p>
              Ana menüde ödemelerin tamamında gezinmek için Ödeme Arşivi
              seçeneğine tıklayın. Sonuçları, tarihe ve ödeme durumuna göre
              filtreleyebilirsiniz. Muhasebe amacıyla, arşivler CSV veya PDF
              formatlarında dışa aktarılabilir.
            </p>
          </Fragment>
        ),
      },
      {
        question: "Günlük ödemelerin hesaplanmasını oluşturabilir miyim?",
        answer: (
          <Fragment>
            <p>
              Tabii ki! Ana menüde Ödemelerin hesaplanması seçeneğine tıklayın
              ve ödemelerin günlük hesap özetini farklı para birimlerinde
              yazdırabilirsiniz.
            </p>
          </Fragment>
        ),
      },
      {
        question: "Hangi sanal para birimleri desteklenir?",
        answer: (
          <Fragment>
            <p>
              Crypto enables payments with 40 cryptocurrencies. Bitcoin, Bitcoin
              Cash, Ether, the GoC token, Litecoin and the Viberate token
              are just some of them. However, you do not need any knowledge
              about them. Once the user scans the QR code, they select the
              payment currency in their crypto wallet application, and the
              process for you is the same in all cases.{" "}
            </p>
            <p>
              In addition to cryptocurrencies, GoCrypto also supports payments
              with pre-deposited Euro value in some countries.{" "}
            </p>
          </Fragment>
        ),
      },
      {
        question: "Müşterilerim ödemek için hangi cüzdanları kullanabilir?",
        answer: (
          <Fragment>
            <p>
              Buyers can use the Elly Wallet, Binance Pay (these two support
              multiple currencies) and the Bitcoin.com Wallet (supports Bitcoin
              Cash). The process is completely the same for you in all cases.{" "}
            </p>
          </Fragment>
        ),
      },
      {
        question:
          "Normal para (sanal olmayan) birimleri ile yapılan ödemeler destekleniyor mu?",
        answer: (
          <Fragment>
            <p>
              Evet, sanal para birimlerine ek olarak bazı ülkelerde önceden
              yatırılmış avro değerine ödemeler de desteklenmektedir.
            </p>
          </Fragment>
        ),
      },
      {
        question: "Müşterim geri ödeme istiyor.",
        answer: (
          <Fragment>
            <p>
              Ödeme başarıyla tamamlandıktan sonra, GoCrypto sistemi
              aracılığıyla para miktarı geri ödemesi yapılamaz. Geri ödeme,
              satıcı tarafından yapılmalıdır.
            </p>
          </Fragment>
        ),
      },
      {
        question:
          "Ödeme oluştururken veya GoCrypto POSu kullanırken sorun yaşıyorum.",
        answer: (
          <Fragment>
            <p>
              <a href="mailto:support@gocrypto.com">support@gocrypto.com</a>{" "}
              adresine e-postayı gönderebilirsiniz.{" "}
            </p>
          </Fragment>
        ),
      },
    ],
  },
};
