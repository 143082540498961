import React, { Fragment } from "react";

export const SLOVAK = {
  LANG_CODE: "sk",
  COUNTRY_CODE: "sk",
  CURRENCY: {
    symbol: "€",
    name: "EUR",
    id: "0",
    minPaymentAmount: "0,01",
    maxPaymentAmount: "15000",
    billingLabel: "EUR",
    traderKey: "",
  },
  FOOTER: {
    copyright: "Eligma Ltd.",
    version: "verzia",
  },
  LOGIN: {
    username: "Používateľské meno",
    password: "Heslo",
    usernameEmptyError: "Zadať používateľské meno",
    passwordEmptyError: "Zadať heslo",
    login: "PRIHLÁSIŤ SA",
    notAuthorized: "Nie je povolené",
    hidePassword: "Skryť",
    showPassword: "Ukázať",
    invalidCredentials: "Nesprávne používateľské meno alebo heslo",
  },
  HEADER: {
    help: "POMOC",
    callAgent: "support@gocrypto.com",
    agentNumber: "",
  },
  SIDEBAR: {
    logout: "Odhlásiť sa",
    newPayment: "Nová platba",
    archive: "Archív",
    billing: "Fakturácia",
    faq: "FAQ",
  },
  ARCHIVE: {
    overviewTitle: "Zoznam prijatých transakcií",
    refreshLabel: "OBNOVIŤ",
    exportLabel: "EXPORT",
    tableHeaders: {
      ID: "ID",
      lastUpdatedTime: "ČAS",
      lastUpdatedDate: "DÁTUM",
      lastUpdated: (
        <span>
          {" "}
          ČAS <span className="mobile-hidden"> DÁTUM </span>{" "}
        </span>
      ),
      amount: "HODNOTA",
      combinedAmount: (
        <span>
          {" "}
          HODNOTA <span className="mobile-hidden"> </span>{" "}
        </span>
      ),
      cryptoCurrency: "MENA",
      cryptoAmount: "HODNOTA V KRYPTOMENE",
      print: "TLAČ",
      status: "STAV",
      referenceNumber: "REFERENCIA",
      revert: "Naspäť",
    },
    export: {
      startDate: "Počiatočný dátum",
      endDate: "Koncový dátum",
      error: "Vybrať rozsah dátumov",
      exportTitle: "Exportovať platby",
      lastMonth: "Minulý mesiac",
      showResults: "ZOBRAZIŤ VÝSLEDKY",
      successfulPayments: "Úspešné platby",
    },
    printLabel: "Tlač",
    print: {
      amountLabel: "Suma meny",
      transactionInfoLabel: "Informácie o transakcii",
      dateTimeLabel: "Dátum a čas",
    },
    emptyList: "V archíve nie sú žiadne platby",
  },
  PAYMENT: {
    stepLabel: "krok",
    fromLabel: "z",
    createPayment: "Vytvoriť platbu",
    paymentReady: "Pripravené na platbu",
    paymentFinished: "Dokončenie platby",
    amount: "Čiastka na úhradu",
    amountErrorLow: "Zadaná suma je príliš nízka",
    amountErrorHigh: "Zadaná suma je príliš vysoká",
    referenceNumberLabel: "Referenčné číslo",
    createPaymentButton: "Pokračovať k platbe",
    cancelPaymentButton: "zrušiť platbu",
    qrCodeReady: "QR kód je pripravený na skenovanie",
    resetPaymentButton: "Nová platba",
    paymentStatus: {
      CRYPTO_PAYMENT_STATUS_OPENED: "OTVORIŤ",
      CRYPTO_PAYMENT_STATUS_IN_PAYMENT: "V PLATBE",
      CRYPTO_PAYMENT_STATUS_PAID: "ÚSPEŠNÁ",
      CRYPTO_PAYMENT_STATUS_PROCESSING: "SPRACÚVAVA SA",
      CRYPTO_PAYMENT_STATUS_AUTO_CLOSED: "VYPRŠANÉ",
      CRYPTO_PAYMENT_STATUS_FAILED: "ZLYHALA",
      CRYPTO_PAYMENT_STATUS_NOT_VALID: "NEPLATNÁ",
      CRYPTO_PAYMENT_STATUS_REVERTED: "PLATBA VRÁTENÁ",
      CRYPTO_PAYMENT_STATUS_CANCELED: "ZRUŠENÉ",
      CRYPTO_PAYMENT_STATUS_UNDERPAID: "PRÍLIŠ NÍZKE",
    },
    paymentStatusLabel: {
      CRYPTO_PAYMENT_STATUS_OPENED: "QR kód je pripravený na skenovanie",
      CRYPTO_PAYMENT_STATUS_PAID: "Platba úspešná",
      CRYPTO_PAYMENT_STATUS_AUTO_CLOSED: "Čas na zaplatenie vypršal",
      CRYPTO_PAYMENT_STATUS_UNDERPAID:
        "Platba je príliš nízka.\nZa účelom asistencie kontaktujte GoCrypto na adrese: support@gocrypto.com",
    },
    currencyNotice: "",
  },
  EXPORT: {
    exportTransactionsLabel: "EXPORTOVAŤ TRANSAKCIE",
    timeRangeLabel: "Časové obdobie",
    fileTypeLabel: "Typ súboru",
    exportLabel: "EXPORT",
    lastMonth: "",
    showResults: "",
  },
  REPORT: {
    dailyReport: {
      title: "Fakturácia",
      exportLabel: "EXPORT",
      print: "TLAČ",
      noTransactions: "Žiadne transakcie",
      currencyLabel: "Hodnota v",
      totalLabel: "Celkom v",
      back: "SPÄŤ",
      amountLabel: "ČIASTKA",
      currencyInLabel: "v",
    },
  },
  ERROR: {
    unknownCause: "Vyskytla sa neočakávaná chyba",
    reload: "Skúste najprv opätovne načítať/vyčistiť vyrovnávaciu pamäť",
    report: "Ak problém pretrváva, nahláste ho",
  },
  GOCRYPTO: "",
  TOOLTIPS: {
    PAYMENT: {
      mobile: {
        0: "Pomocou klávesnice zadajte výšku platby.",
        1: "Stlačte Pokračovať k platbe. Otvorí sa obrazovka na skenovanie QR kódu.",
      },
      tablet: {
        0: "Pomocou klávesnice zadajte výšku platby.",
        1: "Stlačte Pokračovať na platbu. Otvorí sa obrazovka na skenovanie QR kódu.",
      },
      desktop: {
        0: "Pomocou klávesnice zadajte výšku platby.",
        1: "Stlačte Pokračovať na platbu. Otvorí sa obrazovka na skenovanie QR kódu.",
      },
    },
    ARCHIVE: {
      mobile: {
        0: "Zobraziť platby podľa ich času a stavu.",
        1: "Exportujte platby podľa času a úspešnosti vo formáte PDF alebo CSV.",
      },
      tablet: {
        0: "Zobraziť platby podľa ich času a stavu.",
        1: "Exportujte platby podľa času a úspešnosti vo formáte PDF alebo CSV.",
      },
      desktop: {
        0: "Zobraziť platby podľa ich času a stavu.",
        1: "Exportujte platby podľa času a úspešnosti vo formáte PDF alebo CSV.",
      },
    },
    next: "ĎALEJ",
  },
  FAQ: {
    title: "webPOS - často kladené otázky",
    QA: [
      {
        question: "Ako môžem vytvoriť platbu GoCrypto?",
        answer: (
          <Fragment>
            {" "}
            <p>
              {" "}
              V hlavnom menu zvoľte „Nová platba“ a pomocou klávesnice alebo
              dotykovej obrazovky zadajte do vyhradeného poľa požadovanú výšku
              platby. Voliteľne je možné pridať referenčné číslo.{" "}
            </p>{" "}
            <p>
              {" "}
              Kliknite na “Vytvoriť platbu“ a vygeneruje sa vám QR kód.
              Kupujúceho požiadajte, aby si naskenoval tento QR kód alebo v
              prípade, ak obrazovka nie je ľahko dostupná, nálepku, umiestnenú
              na prednej strane vašej pokladne.{" "}
            </p>{" "}
            <p>
              {" "}
              Po dokončení procesu sa zobrazí zelená šípka so stavom „Platba
              úspešná“. To znamená, že ste prijali prostriedky a môžete vystaviť
              faktúru.{" "}
            </p>{" "}
            <p>
              {" "}
              Pre potreby účtovníctva môžete zadať „Archívy“ a vytlačiť si
              potvrdenie.{" "}
            </p>{" "}
          </Fragment>
        ),
      },
      {
        question: "Platba vypršala. Čo to znamená?",
        answer: (
          <Fragment>
            {" "}
            <p>
              {" "}
              Po vytvorení platby sa objaví QR kód spolu s odpočítavaním času.
              Od tohto momentu musí používateľ do 5 minút dokončiť platbu. Ak by
              to trvalo dlhšie, proces by vypršal a museli by ste uskutočniť
              platbu znova.{" "}
            </p>{" "}
          </Fragment>
        ),
      },
      {
        question:
          "Zadal som nesprávnu sumu platby a QR kód je už vygenerovaný. Čo teraz?",
        answer: (
          <Fragment>
            {" "}
            <p>
              {" "}
              Bez obáv, jednoducho kliknite na tlačidlo „Zrušiť platbu“ a proces
              zopakujte.{" "}
            </p>{" "}
          </Fragment>
        ),
      },
      {
        question:
          "Kde si môžem skontrolovať históriu platieb a vykonať ich export?",
        answer: (
          <Fragment>
            {" "}
            <p>
              {" "}
              V hlavnom menu kliknite na položku Archívy a prezrite si celú
              históriu platieb. Výsledky môžete filtrovať podľa stavu a dátumu
              platby. Pre potreby účtovníctva je možné archívy exportovať vo
              formáte CSV alebo PDF.{" "}
            </p>{" "}
          </Fragment>
        ),
      },
      {
        question: "Môžem si vygenerovať denný výpis z účtu?",
        answer: (
          <Fragment>
            {" "}
            <p>
              {" "}
              Samozrejme, kliknite na položku Fakturácia v hlavnom menu. Tu si
              môžete vytlačiť denný výpis, v ktorom sa zlučujú platby
              uskutočnené v jednotlivých menách.{" "}
            </p>{" "}
          </Fragment>
        ),
      },
      {
        question: "Ktoré kryptomeny sú podporované?",
        answer: (
          <Fragment>
            <p>
              Crypto enables payments with 40 cryptocurrencies. Bitcoin, Bitcoin
              Cash, Ether, the GoC token, Litecoin and the Viberate token
              are just some of them. However, you do not need any knowledge
              about them. Once the user scans the QR code, they select the
              payment currency in their crypto wallet application, and the
              process for you is the same in all cases.{" "}
            </p>
            <p>
              In addition to cryptocurrencies, GoCrypto also supports payments
              with pre-deposited Euro value in some countries.{" "}
            </p>
          </Fragment>
        ),
      },
      {
        question: "Ktoré peňaženky môže kupujúci použiť na dokončenie platby?",
        answer: (
          <Fragment>
            <p>
              Buyers can use the Elly Wallet, Binance Pay (these two support
              multiple currencies) and the Bitcoin.com Wallet (supports Bitcoin
              Cash). The process is completely the same for you in all cases.
            </p>
          </Fragment>
        ),
      },
      {
        question: "Je platba bežnou menou podporovaná?",
        answer: (
          <Fragment>
            {" "}
            <p>
              {" "}
              Áno, v niektorých regiónoch podporuje GoCrypto okrem kryptomien aj
              platby s prednabitou hodnotou v eurách.{" "}
            </p>{" "}
          </Fragment>
        ),
      },
      {
        question: "Môj predajca žiada vrátenie platby.",
        answer: (
          <Fragment>
            {" "}
            <p>
              {" "}
              Po úspešnom dokončení platby nie je možné vrátiť prostriedky
              prostredníctvom systému GoCrypto, za vrátenie prostriedkov
              zodpovedá obchodník.{" "}
            </p>{" "}
          </Fragment>
        ),
      },
      {
        question:
          "Mám problémy s vytvorením platby alebo s použitím služby GoCrypto POS.",
        answer: (
          <Fragment>
            {" "}
            <p>
              {" "}
              Môžete poslať e-mail na adresu{" "}
              <a href="mailto:support@gocrypto.com">
                {" "}
                support@gocrypto.com
              </a>.{" "}
            </p>{" "}
          </Fragment>
        ),
      },
    ],
  },
};
